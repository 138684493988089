import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

let apiKey = null;
const urlData = window.location.search.substr(1).split("&");

urlData.forEach(function (e) {
    var value = e.split("=");
    if (value[0] === 'apiKey' && value[1]) {
        apiKey = value[1];
    }
});

const env_mode = require('./env');

const container = document.getElementById('root');
const root = createRoot(container);

const version = env_mode.version;
const debug = env_mode.debug;

var offlineMode = env_mode.offlineMode;
const modeFolder = env_mode.REACT_APP_ENV;

if(offlineMode === false){
    offlineMode = !window.navigator.onLine;
}

console.log('==============================================================');
console.log('Smart Guide Application Version: ' + (version));
console.log('Mode:  ' + (modeFolder === 'dev' ? "Development" : "Production"));
console.log('Debug: ' + (debug ? "On" : "Off"));
console.log('Offline: ' + (offlineMode ? "On" : "Off"));
console.log('==============================================================');

const configs_api = require('./configs/' + modeFolder + '/app');
const configs_google = require('./configs/' + modeFolder + '/google_map');

const configs = {
    api_configs: configs_api,
    google_configs: configs_google
};

var url = configs.api_configs.website + apiKey;

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App configs={configs} locale="en" url={url} apiKey={apiKey} version={version} debug={debug} offlineMode={offlineMode}/>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.register();
reportWebVitals();
