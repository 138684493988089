import React, { Component } from 'react';

class Navbar extends Component {

    render() {
        
        return (<nav className="nav">
                <a href="/" className="">
                Smartguide
                </a>
                <ul>
                    <li>
                        <a title="Smartguide" alt="Smartguide" href="/">Smartguide</a>
                    </li>
                </ul>
        </nav>);
    };
};

export default Navbar;