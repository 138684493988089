import React, { Component } from 'react';

import {
        Menu,
        Drawer,
        CssBaseline,
        AppBar,
        Toolbar,
        List,
        Typography,
        Divider,
        IconButton,
        ListItem,
        ListItemIcon,
        ListItemText,
} from '@mui/material';

import {
    PlayArrow,
    VolumeUp,
    VolumeOff,
    Pause
} from '@mui/icons-material';

import AudioBar from '../AudioPlayer/AudioBar/AudioBar';

import './AudioPlayer.scss';

class AudioPlayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            src: props.src,
            player: null,
            play: false,
            isLoaded: false,
            currentTime: null,
            duration: null,
            muted: false
        };
        
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.setClickedTime = this.setClickedTime.bind(this);
        this.setAudioTime = this.setAudioTime.bind(this);
        this.setAudioData = this.setAudioData.bind(this);
        this.reloadPlayerData = this.reloadPlayerData.bind(this);
        this.setMuted = this.setMuted.bind(this);
        this.changeCurrentTime = this.changeCurrentTime.bind(this);
    }
    
    reloadPlayerData(){
        this.setAudioData();
    }
    
    componentDidMount(e, q){
        if(!this.state.player){
            var player = this.state.player;
            this.setState({
                player: player,
                isLoaded: true,
                currentTime: 0
            });
        }
    }
    
    componentDidUpdate(prev, current){
        if(!this.state.player){
            let player = document.getElementById('audio');
            player.addEventListener("loadeddata", this.setAudioData);
            player.addEventListener("timeupdate", this.setAudioTime);
            this.setState({
                player: player,
                duration: player.duration
            });
        }
    }
    
    changeCurrentTime(e){
        this.setClickedTime(e);
    }
    
    setAudioData = () => {
        var play = this.state.play;
        if(this.state.player.duration === this.state.player.currentTime){
            play = false;
            this.props.isVisited(true);
        }
        if(this.state.player.paused){
            play = false;
        }
        this.setState({
            duration: this.state.player.duration,
            currentTime: this.state.player.currentTime,
            isLoaded: true,
            play: play
        });
    };
    
    setAudioTime = () => {
        
        this.setState({
            currentTime: this.state.player.currentTime
        });
        
        if(this.state.player.duration === this.state.player.currentTime){
            this.props.setAudioItemAsVisited();
            this.setState({
                play: false
            });
        }
    };
    
    play(){
        if(this.state.player){
            this.state.player.play();
            this.setState({
                play: true
            });
        }
    }
    
    pause(){
        if(this.state.player){
            this.state.player.pause();
            this.setState({
                play: false
            });
        }
    }
    
    setClickedTime(time){
        
        let player = this.state.player;
        player.currentTime = time;
        
        this.setState({
            currentTime: time,
            player: player
        });
        
    }
    
    setMuted(){
        let muted = !this.state.muted;
        let player = this.state.player;
        player.muted = muted;
        
        this.setState({
            muted: !this.state.muted,
            player: player
        });
    }

    render() {
        return (<div className="audio-player-wrapper">
                <audio onLoadedData={this.reloadPlayerData} src={this.props.src} id="audio" controls></audio>
                {this.state.isLoaded ?
                <div className="audio-player">
                    <div className="audio-player-controller">{!this.state.play ? <PlayArrow onClick={this.play} /> : <Pause onClick={this.pause} />}</div>
                    <AudioBar changeCurrentTime={this.changeCurrentTime} currentTime={this.state.currentTime} duration={this.state.duration} onTimeUpdate={(time) => this.setClickedTime(time)}/>
                    <div className="audio-player-controller">{!this.state.muted ? <VolumeUp onClick={this.setMuted} /> : <VolumeOff onClick={this.setMuted} />}</div>
                </div>
                : ""}
        </div>);
    }
    ;
}
;

export default AudioPlayer;