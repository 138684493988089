import React, { Component } from 'react';

import {BrowserRouter, Router, Routes, Route, Link} from "react-router-dom";
import CircularProgressWithLabel from './Components/Loader/Loader';
import { DBConfig } from './Components/Database/DBConfig';
import IndexedDB from './Components/Database/IndexedDB';
import { LoadScript } from '@react-google-maps/api';

import i18n from './Components/i18n/i18n';

import {
Box,
        Menu,
        Drawer,
        CssBaseline,
        AppBar,
        Toolbar,
        List,
        Typography,
        Divider,
        IconButton,
        ListItem,
        ListItemIcon,
        ListItemText,
        } from '@mui/material';

import {
Menu as MenuIcon,
        Map as MapIcon,
        Info as InfoIcon,
        Help as HelpIcon,
        PlaylistPlay as PlaylistPlayIcon,
        ChevronLeft as ChevronLeftIcon,
        ChevronRight as ChevronRightIcon,
        } from '@mui/icons-material';

import Navbar from './Components/Navbar/Navbar.js';
import SideMenu from './Components/SideMenu/SideMenu.js';
import Header from './Components/Header/Header.js';

//Import Pages
import Contacts from './Pages/Contacts/Contacts';
import AudioList from './Pages/AudioList/AudioList';
import AudioItem from './Pages/AudioItem/AudioItem';
import Map from './Pages/Map/Map';
import MapOffline from './Pages/Map/MapOffline';
import Help from './Pages/Help/Help';
import Page404 from './Pages/Page404/Page404';

import './App.scss';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            version: props.version,
            configs: props.configs,
            sideMenuStatus: false,
            open: false,
            locale: props.language,
            access: false,
            audioData: [ ],
            url: props.url,
            apiKey: props.apiKey,
            visited: [ ],
            isLoading: true,
            debug: props.debug,
            offlineMode: props.offlineMode
        };

        this.changeMenuAction = this.changeMenuAction.bind(this);
        this.hideMenuAction = this.hideMenuAction.bind(this);
        this.changeLocale = this.changeLocale.bind(this);
        this.getDataFromUrl = this.getDataFromUrl.bind(this);
        this.getDataFromDB = this.getDataFromDB.bind(this);
        this.insertDataIntoDB = this.insertDataIntoDB.bind(this);
        this.updateVisited = this.updateVisited.bind(this);
    }

    componentDidMount() {
        if (this.state.apiKey) {
            window.indexedDB.deleteDatabase(DBConfig.name);
        }
        IndexedDB.init();
        if (this.state.apiKey) {
            this.getDataFromUrl();
        } else {
            this.getDataFromDB();
        }

        let database = DBConfig;
        let request = window.indexedDB.open(database.name, database.version);

        request.onsuccess = event => {
            let db = event.target.result;
            const transaction = db.transaction("visited", "readwrite");
            const objectStore = transaction.objectStore('visited');
            const requestJsonDataObjectStoreData = objectStore.get("smartguide_visited");
            requestJsonDataObjectStoreData.onsuccess = event => {
                this.setState({
                    visited: event.target.result.value
                });
            };
        };
        i18n.changeLanguage(this.props.locale);
    }

    getDataFromDB() {
        let database = DBConfig;
        let request = window.indexedDB.open(database.name, database.version);
        request.onsuccess = event => {
            let db = event.target.result;
            let objectStore = db.transaction("audios", "readwrite").objectStore("audios");
            let requestJsonDataObjectStoreData = objectStore.get("smartguide_audios");
            requestJsonDataObjectStoreData.onsuccess = event => {
                this.setState({
                    audioData: event.target.result.value,
                    isLoading: false
                });
            };
        };
    }

    insertDataIntoDB(data) {
        IndexedDB.update(data);
    }

    getDataFromUrl() {

        fetch(this.state.url)
                .then((response) => response.json())
                .then((response) => {
                    this.setState({
                        audioData: response,
                        isLoading: false
                    });
                    this.insertDataIntoDB(response);
                })
                .catch(err => {

                });
    }

    changeMenuAction = (e) => {
        this.setState({
            open: !this.state.open
        });
    }

    hideMenuAction = (e) => {
        this.setState({
            open: false
        });
    }

    changeLocale(e) {
        i18n.changeLanguage(e);
        this.setState({
            isLoading: false,
            locale: e
        });
        
    }

    updateVisited = (e) => {
        let visited = this.state.visited;
        visited[e] = true;
        this.setState({
            visited: visited
        });
        IndexedDB.updateVisited(e);
    }

    changeLoadingData = (e) => {

//        this.setState({
//            loadingData: e
//        });

    }

    render() {

        if (this.state.isLoading) {
            return <div className="loader" style={{ flexDirection: "column" }}>
            <div><CircularProgressWithLabel /></div><br/>
            <div style={{ color: "#fff", fontWeight: "600", 'maxWidth': "200px" }}><br/>
                Loading the tour points - please wait a moment.</div>
            </div>;
        }
        
        if (this.state.audioData.audios) {
            return <div className="body">
                <Header menuAction={this.changeMenuAction} changeLocale={this.changeLocale} activeMenu={this.state.open} disabledLanguage={false} disabledMenu={false} />
                <SideMenu menuAction={this.changeMenuAction} status={this.state.open} version={this.props.version} debug={this.state.debug} offlineMode={this.state.offlineMode}/>
                <main className="content">
                    {this.state.offlineMode ? <div className='offline-mode-label'>Offline Mode</div> : "" }
                    <div className='body-content'>
                        <LoadScript googleMapsApiKey={this.props.configs.google_configs.map.google_map_api_key}>
                            <Routes>
                                {this.state.offlineMode === false ?
                                    <Route path='/' element={ < Map offlineMode = {this.state.offlineMode} debug = {this.state.debug} configs = {this.props.configs.google_configs.map} audioData = {this.state.audioData} visited = {this.state.visited} version = {this.props.version} / > }/>
                                                                :
                                                            <Route path='/map' element={ < MapOffline offlineMode = {this.state.offlineMode} debug = {this.state.debug} configs = {this.props.configs.google_configs.map} audioData = {this.state.audioData} visited = {this.state.visited} version = {this.props.version} / > }/>
                                                            }
                                                            <Route path={this.state.offlineMode ? "/" : "/audios"} element={ < AudioList audioData = {this.state.audioData} offlineMode = {this.state.offlineMode} / > }/>
                                                            <Route path="/audios/audio-item/:id" element={ < AudioItem audios = {this.state.audioData.audios} updateVisited = {(e) => this.updateVisited(e)} debug = {this.state.debug} / > } />
                                                            <Route path="contacts" element={ < Contacts / > }/>
                                                            <Route path="help" element={ < Help / > }/>
                                                            <Route path="*" element={ < Page404 / > }/>
                                                            <Route path="/page-not-found" element={ < Page404 / > }/>
                                                        </Routes>
                                                    </LoadScript>
                                                </div>
                                            </main>
                                        </div>;
                                                                    }

                                                                    return (<div className="body">
                                                                        <Header menuAction={this.changeMenuAction} changeLocale={this.changeLocale} activeMenu={this.state.open} disabledLanguage={false} disabledMenu={true} />
                                                                        <div className="no-data-message">
                                                                            <div>{i18n.t('noDataMessageNoOrderLabel')}</div><br/>
                                                                            <div>{i18n.t('noDataMessagePurcheLabel')}</div>
                                                                            <a target="_blank" className="no-date-message-link" href="https://www.smartguide.bg">Smartguide</a>
                                                                            <div>{i18n.t('noDataMessageLoadFromYourEmailLabel')}</div>
                                                                        </div>
                                                                    </div>);
                                                                }
                                                                ;
                                                            }
                                                            ;
                                                            export default App;