import React, { Component } from 'react';

import './Page404.scss';

class Page404 extends Component {

    render() {
        
        return (<div>
                Page Not Found
        </div>);
    };
};

export default Page404;