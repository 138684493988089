import React, { Component } from 'react';
import {
Card,
        CardContent,
        CardMedia,
        Typography
        } from '@mui/material';

import { Link } from "react-router-dom";
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import {
Menu as MenuIcon,
        Map as MapIcon,
        Info as InfoIcon,
        Help as HelpIcon,
        PlaylistPlay as PlaylistPlayIcon,
        ChevronLeft as ChevronLeftIcon,
        ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';

import { DBConfig } from '../../Components/Database/DBConfig';
import IndexedDB from '../../Components/Database/IndexedDB';

import i18n from '../../Components/i18n/i18n';

import './Map.scss';

export default class Map extends Component {

    constructor(props) {

        var audioId = (window.location.href.split('?')[1] && window.location.href.split('?')[1].split('=')[1]) ? (window.location.href.split('?')[1].split('=')[1]) : null;
        super(props);
        this.state = {
            version: props.version,
            locale: props.configs.default_locale,
            zoom: props.configs.zoom,
            configs: props.configs,
            styles: props.configs.styles,
            showMyLocation: false,
            isLoaded: false,
            areLocationsLoaded: 1,
            audioData: props.audioData,
            center: audioId && props.audioData.audios[audioId] ? {lat: parseFloat(props.audioData.audios[audioId].point.lat), lng: parseFloat(props.audioData.audios[audioId].point.lng)} : null,
            selectedItemId: audioId,
            visited: props.visited,
            position: {
                lat: 41,
                lng: 42
            },
            offlineMode: props.offlineMode,
            debug: props.debug,
            imageLines: [ ],
            pointersHeight: "35px",
            imagePoints: [
                {
                    "name": "Rila Monastery 1",
                    "points": [ 27, 13 ],
                    "key": 0,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 2",
                    "points": [ 27, 16 ],
                    "key": 1,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 3",
                    "points": [ 27, 19 ],
                    "key": 2,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 4",
                    "points": [ 21, 22 ],
                    "key": 3,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 5",
                    "points": [ 32, 21 ],
                    "key": 4,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 6",
                    "points": [ 34, 28 ],
                    "key": 5,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 7",
                    "points": [ 27, 26 ],
                    "key": 6,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 8",
                    "points": [ 28, 40 ],
                    "key": 7,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 9",
                    "points": [ 25, 35 ],
                    "key": 8,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 10",
                    "points": [ 29, 30 ],
                    "key": 9,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 11",
                    "points": [ 19, 28 ],
                    "key": 10,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 12",
                    "points": [ 20, 31 ],
                    "key": 11,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Entrance",
                    "points": [ 31, 3 ],
                    "key": 12,
                    "image": "/images/my-current-location.png"
                }
            ]
        };

        this.getLocation = this.getLocation.bind(this);
        this.getCoordinates = this.getCoordinates.bind(this);
        this.redirectToAudioFile = this.redirectToAudioFile.bind(this);
        this.moveToMyLocation = this.moveToMyLocation.bind(this);
        this.buildImageMaxtrixArray = this.buildImageMaxtrixArray.bind(this);
        this.getPointPosition = this.getPointPosition.bind(this);
        this.changePointersSize = this.changePointersSize.bind(this);
    }

    componentDidMount() {
        const cleaningUp = true
        const isBrowser = typeof document !== "undefined" // require('@react-google-maps/api/src/utils/isbrowser')
        const isAlreadyLoaded = window.google && window.google.maps && document.querySelector('body.first-hit-completed') // AJAX page loading system is adding this class the first time the app is loaded
        if (!isAlreadyLoaded && isBrowser) {
            // @ts-ignore
            if (window.google && !cleaningUp) {
                console.error("google api is already presented")
                return
            }
        }

        this.getLocation();
        this.generateImageLines();
        
        var lat = null;
        var lng = null;
        
        navigator.geolocation.getCurrentPosition(function (position) {
            lat = position.coords.latitude;
            lng = position.coords.longitude;
        });
    }

    redirectToAudioFile = (audioIndex) => {

        window.location.href = "/audios/audio-item/" + audioIndex;

    }

    getLocation() {
        navigator.geolocation.getCurrentPosition(this.getCoordinates);
    }

    getCoordinates(position) {
        this.setState({
            position: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            },
            showMyLocation: true
        });
    }

    moveToMyLocation() {
        if (this.state.showMyLocation) {
            this.setState({
                center: this.state.position
            });
        }
    }

    buildImageMaxtrixArray(rows, columns) {

        var arr = [ ];

        for (var i = 0; i < rows; i++) {
            arr[i] = [ ];
            for (var j = 0; j < columns; j++) {
                arr[i][j] = i + "," + j;
            }
        }

        return arr;
    }

    generateImageLines() {

        if (this.state.offlineMode === false) {
            return;
        }

        var imageContainer = document.querySelector('.map-offline-image');
        var width = imageContainer.offsetWidth;
        var height = imageContainer.offsetHeight;
        var rows = (width / (width * 0.02));
        var colums = (height / (height * 0.02));
        var prepArray = this.buildImageMaxtrixArray(rows, colums);
        this.setState({
            imageLines: prepArray
        })
    }

    getPointPosition(row, column) {

        var result = {};

        this.state.imagePoints.map((e, i) => {
            if (e.points[0] == row && e.points[1] == column) {
                result = e;
            }
        });

        return result;
    }
    
    changePointersSize(scale){
        
        var size = 35 / scale;
        
        this.setState({
            pointersHeight: size
        });
    }

    render() {

        if (this.state.offlineMode === this.state.offlineMode) {

            var myLocation = '';
            var centerPosition = this.state.center ? this.state.center : {lat: parseFloat(this.state.audioData.audios[0].point.lat), lng: parseFloat(this.state.audioData.audios[0].point.lng)};
            var zoom = 13;
            var isDisabledGPS = (this.state.showMyLocation === false) ? <span className="disabled-location">- {i18n.t('mapsYourLocationIsDisabledLabel')}</span> : '';

            if (this.state.showMyLocation === false) {
                myLocation = <div>
                    <div className="">{i18n.t('mapsLocationTurnOnMessage')}</div></div>
            }
            return (<div>
            {myLocation}
            <div className="legend">
                <div className="item" onClick={this.moveToMyLocation}>
                    <img className="image" src="/images/my-current-location.png?{this.props.version}" alt="Logo" />
                    <span className="label">{i18n.t('mapsYourLocationLabel')} {isDisabledGPS}</span>
                </div>
                <div className="item">
                    <img className="image" src="/images/default-location.png?{this.props.version}" alt="Logo" /><span className="label">{i18n.t('mapsAudioPointDefault')}</span>
                </div>
                <div className="item">
                    <img className="image" src="/images/visited-location.png?{this.props.version}" alt="Logo" /><span className="label">{i18n.t('mapsAudioPointVisited')}</span>
                </div>
            </div>
            <GoogleMap mapContainerStyle={this.state.styles} center={centerPosition} zoom={zoom} zIndex={1000}>
                {this.state.showMyLocation ?
                                    <MarkerF
                                        name="My position"
                                        position={this.state.position}
                                        title="YOU"
                                        icon={{url: "/images/my-current-location.png?" + this.props.version}}
                                        zIndex={9999}
                                        >
                                    </MarkerF>
                                            : "Please allow to track your location"}
                {this.state.audioData.audios.map((audio, index) => {

                                                return (<Link key={index} to="/audios/audio-item/{index}">
                                                <MarkerF
                                                    key={index}
                                                    name={audio.title}
                                                    onClick={(e) => this.redirectToAudioFile(index)}
                                                    position={{lat: parseFloat(audio.point.lat), lng: parseFloat(audio.point.lng)}}
                                                    title={audio.title}
                                                    zIndex={9999}
                                                    icon={{url: (this.state.visited[index] === undefined) ? "/images/default-location.png?" + this.props.version : "/images/visited-location.png?" + this.props.version}}
                                                    >
                                                </MarkerF>
                                                </Link>);
                                            })}
            </GoogleMap>
        </div>
                    );
        } else {

            var mapOfflineImageClass = this.state.debug ? "map-offline-image debug" : "map-offline-image";

            return (<div>
                    <div className="map-offline">
                        <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0} alignmentAnimation={{ sizeX: 0, sizeY: 0 }} >
                   {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
                <TransformComponent>
                        <div className="map-offline-point-wrapper">
                            <img src="/images/rm-plan.png" style={{maxWidth: "100%"}} />
                            <div className={mapOfflineImageClass}>
                                {this.state.imageLines.map((line, index) => {
                                                return (<div className="map-offline-row" key={index}>
                                                    {line.map((row, i) => {
                                                                                                                var point = this.getPointPosition(index, i);
                                                                                                                var title = point.name ? point.name : "";
                                                                                                                if (this.state.debug || !point) {
                                                                                                                    title = index + ", " + i;
                                                                                                                } else if (!this.state.debug && point.name) {
                                                                                                                    title = point.name;
                                                                                                                }
                                                                                                                return (<div className="map-offline-block" title={title} key={index + "_" + i} data-block={index + "," + i}>
                                                                                                                    {point.name ?
                                                                                                                                                                <Link className="map-offline-point audio-link" to={"/audios/audio-item/" + this.getPointPosition(index, i).key}>
                                                                                                                                                                <div className="map-offline-point-img">
                                                                                                                                                                    <img style={{ height :this.state.pointersHeight }} src={point.image}/>
                                                                                                                                                                </div>
                                                                                                                                                                </Link>
                                                                                                                                                                                                : ''}
                                                                                                                </div>);
                                                                                                            })}
                                                </div>);
                                            })}
                            </div>
                            <div></div>
                        </div>
                </TransformComponent>
                    <div className="zoom-buttons-wrapper">
                        <div className="zoom-buttons">
                        <button onClick={() => this.changePointersSize(rest.state.scale, zoomIn())}>Zoom +</button>
                        <button onClick={() => this.changePointersSize(rest.state.scale, zoomOut())}>Zoom -</button>
                        <button onClick={() => this.changePointersSize(1, resetTransform())}>Zoom Reset</button>
                        </div>
                    </div>
                </React.Fragment>
                        )}
                </TransformWrapper>
                        </div>
            </div>);
        }
    }
}