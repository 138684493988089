import { DBConfig } from './DBConfig';

class IndexedDB {

    static init() {
        let database = DBConfig;
        let request = window.indexedDB.open(database.name, database.version);
        request.onupgradeneeded = event => {
            var db = event.target.result;

            // Create an objectStore to hold information about our smarguideDB
            var objectStore = db.createObjectStore("audios", {keyPath: "name"});
            var objectStoreVisited = db.createObjectStore("visited", {keyPath: "name"});
            objectStore.add(database.data);
            objectStoreVisited.add(database.visited);
        };
    }

    static update(newData) {

        let database = DBConfig;
        let request = window.indexedDB.open(database.name, database.version);
        request.onsuccess = event => {
            const db = event.target.result;
            const transaction = db.transaction("audios", "readwrite");
            const objectStore = transaction.objectStore('audios');
            const objectStoreRequest = objectStore.put({name: "smartguide_audios", value: newData});

        };
    }

    static updateVisited(id) {

        let database = DBConfig;
        let request = window.indexedDB.open(database.name, database.version);
        request.onsuccess = event => {
            const db = event.target.result;
            const transaction = db.transaction("visited", "readwrite");
            const objectStore = transaction.objectStore('visited');
            const requestJsonDataObjectStoreData = objectStore.get("smartguide_visited");
            requestJsonDataObjectStoreData.onsuccess = event => {
                let visitedArray = event.target.result.value;
                let newData = visitedArray ? visitedArray : {};
                newData[id] = true;
                const objectStoreRequest = objectStore.put({name: "smartguide_visited", value: newData});
            };

        };
    }

    static get() {
        let database = DBConfig;
        let request = window.indexedDB.open(database.name, database.version);
        request.onsuccess = event => {
            let db = event.target.result;
            let objectStore = db.transaction("audios", "readwrite").objectStore("audios");
            let requestJsonDataObjectStoreData = objectStore.get("smartguide_audios");
            requestJsonDataObjectStoreData.onsuccess = event => {
                return event.target.result;
            };

        };
    }

    databaseError() {
        console.error('Error');
    }

}
;

export default IndexedDB;