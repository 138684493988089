import React, { Component } from 'react';

import './AudioBar.scss';

class AudioBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            duration: props.duration,
            currentTime: props.currentTime,
            onTimeUpdate: props.onTimeUpdate,
            width: 10,
            widthLine: 0,
            widthCircle: 0
        };

        this.formatTime = this.formatTime.bind(this);
        this.onLineClick = this.onLineClick.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
    }

    formatTime(time) {
        let minutes = Math.floor(time / 60);
        let timeForSeconds = time - (minutes * 60);
        let seconds = Math.floor(timeForSeconds);
        let secondsReadable = seconds > 9 ? seconds : `0${seconds}`;
        return `${minutes}:${secondsReadable}`;
    }

    onMouseDown = (e) => {
        
        console.log(e);
        
    }

    onMouseUp = (e) => {
        
        console.log(e);
        
    }

    onLineClick = (e) => {
        
        const clickPositionInPage = e.pageX || e.touches[0].pageX;
        const line = document.querySelector(".line");
        const lineStart = line.getBoundingClientRect().left + window.scrollX;
        const lineWidth = line.offsetWidth;
        const clickPositionInBar = clickPositionInPage - lineStart;
        const timePerPixel = this.state.duration / lineWidth;
        
        this.props.changeCurrentTime((parseInt(clickPositionInBar)/100) * this.props.duration);

    }

    render() {
        var widthLine = parseInt((((parseInt(this.props.currentTime) / parseInt(this.props.duration)) * 100))) + "px";
        var widthCircle = parseInt((((parseInt(this.props.currentTime) / parseInt(this.props.duration)) * 100) - parseFloat(10))) + "px";
        return (<div className="audio-bar">
            <div className="time">{this.formatTime(this.props.currentTime)}</div> / <div className="duration">{this.formatTime(this.props.duration)}</div>
            <div className="line" onClick={(e) => this.onLineClick(e)}>
                <div className="line-content" style={{width: widthLine}}></div>
                <div className="circle" onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp} style={{margin: "0 5px 0 5px", left: widthCircle}}></div>
            </div>
        </div>);
    }
    ;
}
;

export default AudioBar;