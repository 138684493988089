import React, { Component } from 'react';

import {Link} from "react-router-dom";

import {
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import {
        Map as MapIcon,
        Info as InfoIcon,
        Help as HelpIcon,
        PlaylistPlay as PlaylistPlayIcon
        } from '@mui/icons-material';

import i18n from '../../Components/i18n/i18n';

import './SideMenu.scss';

class SideMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuAction: props.menuAction,
            offlineMode: props.offlineMode
        };
    }

    className() {
        if (this.state.status) {
            return "side-menu active";
        } else {
            return "side-menu";
        }
    }

    render() {

        let className = (this.props.status) ? "side-menu active" : "side-menu";

        return (<div className={className}>
            <nav>
                <ul>
                    {this.state.offlineMode === false ?
                    <li>
                        <Link onClick={this.props.menuAction} to="/">
                            <ListItem button className="icon-wrapper">
                                <ListItemIcon className="icon-item"><MapIcon/></ListItemIcon>
                                <ListItemText primary={i18n.t('menuMapTitle')}/>
                            </ListItem>
                        </Link>
                    </li>
                    : ''}
                    <li>
                        <Link onClick={this.props.menuAction} to={this.state.offlineMode ? '/' : '/audios'}>
                            <ListItem button className="icon-wrapper">
                                <ListItemIcon className="icon-item"><PlaylistPlayIcon/></ListItemIcon>
                                <ListItemText primary={i18n.t('menuAudioListTitle')}/>
                            </ListItem>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={this.props.menuAction} to="/contacts">
                            <ListItem button className="icon-wrapper">
                                <ListItemIcon className="icon-item"><InfoIcon/></ListItemIcon>
                                <ListItemText primary={i18n.t('menuContactsTitle')}/>
                            </ListItem>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={this.props.menuAction} to="/help">
                            <ListItem button className="icon-wrapper">
                                <ListItemIcon className="icon-item"><HelpIcon/></ListItemIcon>
                                <ListItemText primary={i18n.t('menuHelpTitle')}/>
                            </ListItem>
                        </Link>
                    </li>
                </ul>
                <div className='version'>v{this.props.version}</div>
            </nav>
        </div>);
    }
    ;
}
;

export default SideMenu;