import React, { Component } from 'react';

import i18n from '../i18n/i18n';

import {
        AppBar,
        Toolbar,
        Typography,
        IconButton
} from '@mui/material';

import {
        Close as CloseIcon,
        Menu as MenuIcon,
        Replay as ReplayIcon
} from '@mui/icons-material';

import './Header.scss';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuAction: props.menuAction,
            activeMenu: props.activeMenu,
            disabledMenu: props.disabledMenu,
            disabledLanguage: props.disabledLanguage,
            locale: props.locale
        };
        
        this.reloadThePage = this.reloadThePage.bind(this);
    }

    reloadThePage() {

        window.location.reload();
    }

    render() {
        
        return (<AppBar position="fixed" className="appBar">
            <Toolbar>
                { this.props.disabledMenu === false ?
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.props.menuAction}
                    edge="start"
                    className="menuButton"
                    >
                    {this.props.activeMenu ? <CloseIcon/> : <MenuIcon/>}
                </IconButton>
                : '' }
                <Typography variant="h6" noWrap>
                    Smart Guide
                </Typography>
                { this.state.disabledLanguage === false ?
                <select className="languageSelect" onChange={(e) => this.props.changeLocale(e.target.value)}>
                    <option value="en"  className="languageSelectOptions">EN</option>
                    <option value="es"  className="languageSelectOptions">ES</option>
                    <option value="it"  className="languageSelectOptions">IT</option>
                    <option value="bg"  className="languageSelectOptions">BG</option>
                    <option value="ru"  className="languageSelectOptions">RU</option>
                </select>
                : '' }
            </Toolbar>
        </AppBar>);
    }
    ;
}
;

export default Header;