import React, { Component } from 'react';

import './Help.scss';

class Help extends Component {

    render() {
        
        return (<div>
                <h1>Help</h1>
        </div>);
    };
};

export default Help;