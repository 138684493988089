import React, { Component } from 'react';

import i18n from '../../Components/i18n/i18n';

import {
    Email as EmailIcon,
    Phone as PhoneIcon,
    PinDrop as PinDropIcon,
    Web as WebIcon
} from '@mui/icons-material';

import './Contacts.scss';

class Contacts extends Component {

    render() {
        
        return (<div>
                <h1>{i18n.t('pages.contacts')}</h1>
                <div className="row"><WebIcon className="icon"/> {i18n.t("contactsWebsiteLabel")}: <a href="https://smartguide.bg">https://smartguide.bg</a></div>
                <div className="row"><EmailIcon className="icon"/> {i18n.t('contactsEmailLabel')}: <a href="mailto:office@smartguide.bg">office@smartguide.bg</a></div>
                <div className="row"><PhoneIcon className="icon"/> {i18n.t('contactsPhoneLabel')}: <a href="mailto:+359883328292">+358 883 328 292</a></div>
                <div className="row"><PinDropIcon className="icon"/> {i18n.t('contactsAddressLabel')}: {i18n.t('contactsAddress')}</div>
            </div>);
    };
};

export default Contacts;