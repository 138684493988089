import React, { Component } from 'react';
import {
Card,
        CardContent,
        CardMedia,
        Typography
        } from '@mui/material';

import { Link } from "react-router-dom";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import {
Menu as MenuIcon,
        Map as MapIcon,
        Info as InfoIcon,
        Help as HelpIcon,
        PlaylistPlay as PlaylistPlayIcon,
        ChevronLeft as ChevronLeftIcon,
        ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';

import i18n from '../../Components/i18n/i18n';

import './Map.scss';

export default class MapOffline extends Component {

    constructor(props) {
        
        var audioId = (window.location.href.split('?')[1] && window.location.href.split('?')[1].split('=')[1]) ? (window.location.href.split('?')[1].split('=')[1]) : null;
        super(props);
        this.state = {
            audioData: props.audioData,
            selectedItemId: audioId,
            imageLines: [ ],
            pointersHeight: "35px",
            debug: props.debug,
            map: '/images/rm-plan.png',
            imagePoints: [
                {
                    "name": "Rila Monastery 1",
                    "points": [ 27, 13 ],
                    "key": 0,
                    "image": "/images/default-location.png",
                },
                {
                    "name": "Rila Monastery 2",
                    "points": [ 27, 16 ],
                    "key": 1,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 3",
                    "points": [ 27, 19 ],
                    "key": 2,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 4",
                    "points": [ 21, 22 ],
                    "key": 3,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 5",
                    "points": [ 32, 21 ],
                    "key": 4,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 6",
                    "points": [ 34, 28 ],
                    "key": 5,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 7",
                    "points": [ 27, 26 ],
                    "key": 6,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 8",
                    "points": [ 28, 40 ],
                    "key": 7,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 9",
                    "points": [ 25, 35 ],
                    "key": 8,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 10",
                    "points": [ 29, 30 ],
                    "key": 9,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 11",
                    "points": [ 19, 28 ],
                    "key": 10,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Rila Monastery 12",
                    "points": [ 20, 31 ],
                    "key": 11,
                    "image": "/images/default-location.png"
                },
                {
                    "name": "Entrance",
                    "points": [ 31, 3 ],
                    "key": 12,
                    "image": "/images/my-current-location.png"
                }
            ]
        };
        
        this.buildImageMaxtrixArray = this.buildImageMaxtrixArray.bind(this);
        this.getPointPosition = this.getPointPosition.bind(this);
        this.changePointersSize = this.changePointersSize.bind(this);
    }

    componentDidMount() {
        this.generateImageLines();
        this.setState({
            map: this.state.audioData.audios[this.state.selectedItemId] ? this.state.audioData.audios[this.state.selectedItemId].map : null
        });
    }

    redirectToAudioFile = (audioIndex) => {
        window.location.href = "/audios/audio-item/" + audioIndex;
    }

    getLocation() {
        navigator.geolocation.getCurrentPosition(this.getCoordinates);
    }

    buildImageMaxtrixArray(rows, columns) {

        var arr = [ ];

        for (var i = 0; i < rows; i++) {
            arr[i] = [ ];
            for (var j = 0; j < columns; j++) {
                arr[i][j] = i + "," + j;
            }
        }

        return arr;
    }

    generateImageLines() {

        if (this.state.offlineMode === false) {
            return;
        }

        var imageContainer = document.querySelector('.map-offline-image');
        var width = imageContainer.offsetWidth;
        var height = imageContainer.offsetHeight;
        var rows = (width / (width * 0.02));
        var colums = (height / (height * 0.02));
        var prepArray = this.buildImageMaxtrixArray(rows, colums);
        this.setState({
            imageLines: prepArray
        })
    }

    getPointPosition(row, column) {

        var result = {};

        this.state.imagePoints.map((e, i) => {
            if (e.points[0] == row && e.points[1] == column) {
                result = e;
            }
        });

        return result;
    }
    
    changePointersSize(scale){
        
        var size = 35 / scale;
        
        this.setState({
            pointersHeight: size
        });
    }

    render() {
        var mapOfflineImageClass = this.state.debug ? "map-offline-image debug" : "map-offline-image";

            return (<div>
                    <div className="map-offline">
                        <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0} alignmentAnimation={{ sizeX: 0, sizeY: 0 }} >
                   {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
                <TransformComponent>
                        <div className="map-offline-point-wrapper">
                            <img src={this.state.map} style={{maxWidth: "100%"}} />
                            <div className={mapOfflineImageClass}>
                                {this.state.imageLines.map((line, index) => {
                                                return (<div className="map-offline-row" key={index}>
                                                    {line.map((row, i) => {
                                                        var point = this.getPointPosition(index, i);
                                                        var title = point.name ? point.name : "";
                                                        if (this.state.debug || !point) {
                                                            title = index + ", " + i;
                                                        } else if (!this.state.debug && point.name) {
                                                            title = point.name;
                                                        }
                                                        return (<div className="map-offline-block" title={title} key={index + "_" + i} data-block={index + "," + i}>
                                                        {point.name ?
                                                            <Link className="map-offline-point audio-link" to={"/audios/audio-item/" + this.getPointPosition(index, i).key}>
                                                            <div className="map-offline-point-img">
                                                                <img style={{ height :this.state.pointersHeight }} src={point.image}/>
                                                            </div>
                                                            </Link>
                                                        : ''}
                                                        </div>);
                                                    })}
                                                </div>);
                                            })}
                            </div>
                            <div></div>
                        </div>
                </TransformComponent>
                    <div className="zoom-buttons-wrapper">
                        <div className="zoom-buttons">
                        <button onClick={() => this.changePointersSize(rest.state.scale, zoomIn())}>Zoom +</button>
                        <button onClick={() => this.changePointersSize(rest.state.scale, zoomOut())}>Zoom -</button>
                        <button onClick={() => this.changePointersSize(1, resetTransform())}>Zoom Reset</button>
                        </div>
                    </div>
                </React.Fragment>
                        )}
                </TransformWrapper>
                        </div>
            </div>);
    }
}